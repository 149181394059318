/* tslint:disable */
/* eslint-disable */
/**
 * Zsegment
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AtlasMapping } from '../Models';
// @ts-ignore
import { ClassInspectionRequest } from '../Models';
// @ts-ignore
import { Hl7V2InspectionRequest } from '../Models';
// @ts-ignore
import { JsonInspectionRequest } from '../Models';
// @ts-ignore
import { MappingFileType } from '../Models';
// @ts-ignore
import { ProcessMappingRequest } from '../Models';
// @ts-ignore
import { X12InspectionRequest } from '../Models';
// @ts-ignore
import { XmlInspectionRequest } from '../Models';
/**
 * AtlasControllerApi - axios parameter creator
 * @export
 */
export const AtlasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MappingFileType} mappingFormat 
         * @param {number} mappingDefinitionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMappingRequest: async (mappingFormat: MappingFileType, mappingDefinitionId: number, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingFormat' is not null or undefined
            assertParamExists('createMappingRequest', 'mappingFormat', mappingFormat)
            // verify required parameter 'mappingDefinitionId' is not null or undefined
            assertParamExists('createMappingRequest', 'mappingDefinitionId', mappingDefinitionId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMappingRequest', 'body', body)
            const localVarPath = `/atlas/mapping/{mappingFormat}/{mappingDefinitionId}`
                .replace(`{${"mappingFormat"}}`, encodeURIComponent(String(mappingFormat)))
                .replace(`{${"mappingDefinitionId"}}`, encodeURIComponent(String(mappingDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAdmFile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atlas/mapping/adm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldActions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atlas/fieldActions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MappingFileType} mappingFormat 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMappingRequest: async (mappingFormat: MappingFileType, mappingDefinitionId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingFormat' is not null or undefined
            assertParamExists('getMappingRequest', 'mappingFormat', mappingFormat)
            // verify required parameter 'mappingDefinitionId' is not null or undefined
            assertParamExists('getMappingRequest', 'mappingDefinitionId', mappingDefinitionId)
            const localVarPath = `/atlas/mapping/{mappingFormat}/{mappingDefinitionId}`
                .replace(`{${"mappingFormat"}}`, encodeURIComponent(String(mappingFormat)))
                .replace(`{${"mappingDefinitionId"}}`, encodeURIComponent(String(mappingDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} format 
         * @param {string} delimiter 
         * @param {boolean} firstRecordAsHeader 
         * @param {boolean} skipHeaderRecord 
         * @param {string} headers 
         * @param {string} commentMarker 
         * @param {string} escape 
         * @param {boolean} ignoreEmptyLines 
         * @param {boolean} ignoreHeaderCase 
         * @param {boolean} ignoreSurroundingSpaces 
         * @param {string} nullString 
         * @param {string} quote 
         * @param {boolean} allowDuplicateHeaderNames 
         * @param {boolean} allowMissingColumnNames 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectCsv: async (format: string, delimiter: string, firstRecordAsHeader: boolean, skipHeaderRecord: boolean, headers: string, commentMarker: string, escape: string, ignoreEmptyLines: boolean, ignoreHeaderCase: boolean, ignoreSurroundingSpaces: boolean, nullString: string, quote: string, allowDuplicateHeaderNames: boolean, allowMissingColumnNames: boolean, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'format' is not null or undefined
            assertParamExists('inspectCsv', 'format', format)
            // verify required parameter 'delimiter' is not null or undefined
            assertParamExists('inspectCsv', 'delimiter', delimiter)
            // verify required parameter 'firstRecordAsHeader' is not null or undefined
            assertParamExists('inspectCsv', 'firstRecordAsHeader', firstRecordAsHeader)
            // verify required parameter 'skipHeaderRecord' is not null or undefined
            assertParamExists('inspectCsv', 'skipHeaderRecord', skipHeaderRecord)
            // verify required parameter 'headers' is not null or undefined
            assertParamExists('inspectCsv', 'headers', headers)
            // verify required parameter 'commentMarker' is not null or undefined
            assertParamExists('inspectCsv', 'commentMarker', commentMarker)
            // verify required parameter 'escape' is not null or undefined
            assertParamExists('inspectCsv', 'escape', escape)
            // verify required parameter 'ignoreEmptyLines' is not null or undefined
            assertParamExists('inspectCsv', 'ignoreEmptyLines', ignoreEmptyLines)
            // verify required parameter 'ignoreHeaderCase' is not null or undefined
            assertParamExists('inspectCsv', 'ignoreHeaderCase', ignoreHeaderCase)
            // verify required parameter 'ignoreSurroundingSpaces' is not null or undefined
            assertParamExists('inspectCsv', 'ignoreSurroundingSpaces', ignoreSurroundingSpaces)
            // verify required parameter 'nullString' is not null or undefined
            assertParamExists('inspectCsv', 'nullString', nullString)
            // verify required parameter 'quote' is not null or undefined
            assertParamExists('inspectCsv', 'quote', quote)
            // verify required parameter 'allowDuplicateHeaderNames' is not null or undefined
            assertParamExists('inspectCsv', 'allowDuplicateHeaderNames', allowDuplicateHeaderNames)
            // verify required parameter 'allowMissingColumnNames' is not null or undefined
            assertParamExists('inspectCsv', 'allowMissingColumnNames', allowMissingColumnNames)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('inspectCsv', 'body', body)
            const localVarPath = `/atlas/csv/inspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (delimiter !== undefined) {
                localVarQueryParameter['delimiter'] = delimiter;
            }

            if (firstRecordAsHeader !== undefined) {
                localVarQueryParameter['firstRecordAsHeader'] = firstRecordAsHeader;
            }

            if (skipHeaderRecord !== undefined) {
                localVarQueryParameter['skipHeaderRecord'] = skipHeaderRecord;
            }

            if (headers !== undefined) {
                localVarQueryParameter['headers'] = headers;
            }

            if (commentMarker !== undefined) {
                localVarQueryParameter['commentMarker'] = commentMarker;
            }

            if (escape !== undefined) {
                localVarQueryParameter['escape'] = escape;
            }

            if (ignoreEmptyLines !== undefined) {
                localVarQueryParameter['ignoreEmptyLines'] = ignoreEmptyLines;
            }

            if (ignoreHeaderCase !== undefined) {
                localVarQueryParameter['ignoreHeaderCase'] = ignoreHeaderCase;
            }

            if (ignoreSurroundingSpaces !== undefined) {
                localVarQueryParameter['ignoreSurroundingSpaces'] = ignoreSurroundingSpaces;
            }

            if (nullString !== undefined) {
                localVarQueryParameter['nullString'] = nullString;
            }

            if (quote !== undefined) {
                localVarQueryParameter['quote'] = quote;
            }

            if (allowDuplicateHeaderNames !== undefined) {
                localVarQueryParameter['allowDuplicateHeaderNames'] = allowDuplicateHeaderNames;
            }

            if (allowMissingColumnNames !== undefined) {
                localVarQueryParameter['allowMissingColumnNames'] = allowMissingColumnNames;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Hl7V2InspectionRequest} hl7V2InspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectHl7v2: async (hl7V2InspectionRequest: Hl7V2InspectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hl7V2InspectionRequest' is not null or undefined
            assertParamExists('inspectHl7v2', 'hl7V2InspectionRequest', hl7V2InspectionRequest)
            const localVarPath = `/atlas/hl7v2/inspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hl7V2InspectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClassInspectionRequest} classInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectJava: async (classInspectionRequest: ClassInspectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'classInspectionRequest' is not null or undefined
            assertParamExists('inspectJava', 'classInspectionRequest', classInspectionRequest)
            const localVarPath = `/atlas/java/inspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(classInspectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JsonInspectionRequest} jsonInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectJson: async (jsonInspectionRequest: JsonInspectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jsonInspectionRequest' is not null or undefined
            assertParamExists('inspectJson', 'jsonInspectionRequest', jsonInspectionRequest)
            const localVarPath = `/atlas/json/inspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonInspectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {X12InspectionRequest} x12InspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectX12: async (x12InspectionRequest: X12InspectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'x12InspectionRequest' is not null or undefined
            assertParamExists('inspectX12', 'x12InspectionRequest', x12InspectionRequest)
            const localVarPath = `/atlas/x12/inspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(x12InspectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {XmlInspectionRequest} xmlInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectXml: async (xmlInspectionRequest: XmlInspectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xmlInspectionRequest' is not null or undefined
            assertParamExists('inspectXml', 'xmlInspectionRequest', xmlInspectionRequest)
            const localVarPath = `/atlas/xml/inspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(xmlInspectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLibraryClasses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atlas/library/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappingBuilderClasses: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atlas/mappingBuilders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filter 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappings: async (filter: string, mappingDefinitionId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('listMappings', 'filter', filter)
            // verify required parameter 'mappingDefinitionId' is not null or undefined
            assertParamExists('listMappings', 'mappingDefinitionId', mappingDefinitionId)
            const localVarPath = `/atlas/mappings/{mappingDefinitionId}`
                .replace(`{${"mappingDefinitionId"}}`, encodeURIComponent(String(mappingDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdmFile: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('processAdmFile', 'file', file)
            const localVarPath = `/atlas/mapping/adm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessMappingRequest} processMappingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMappingRequest: async (processMappingRequest: ProcessMappingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processMappingRequest' is not null or undefined
            assertParamExists('processMappingRequest', 'processMappingRequest', processMappingRequest)
            const localVarPath = `/atlas/mapping/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processMappingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshFieldActions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atlas/fieldActions/reload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMappingRequest: async (mappingDefinitionId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingDefinitionId' is not null or undefined
            assertParamExists('removeMappingRequest', 'mappingDefinitionId', mappingDefinitionId)
            const localVarPath = `/atlas/mapping/{mappingDefinitionId}`
                .replace(`{${"mappingDefinitionId"}}`, encodeURIComponent(String(mappingDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAllMappings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atlas/mapping/RESET/ALL`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetMappingById: async (mappingDefinitionId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingDefinitionId' is not null or undefined
            assertParamExists('resetMappingById', 'mappingDefinitionId', mappingDefinitionId)
            const localVarPath = `/atlas/mapping/RESET/{mappingDefinitionId}`
                .replace(`{${"mappingDefinitionId"}}`, encodeURIComponent(String(mappingDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMappingRequest: async (mappingDefinitionId: number, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingDefinitionId' is not null or undefined
            assertParamExists('updateMappingRequest', 'mappingDefinitionId', mappingDefinitionId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMappingRequest', 'body', body)
            const localVarPath = `/atlas/mapping/{mappingDefinitionId}`
                .replace(`{${"mappingDefinitionId"}}`, encodeURIComponent(String(mappingDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLibrary: async (relativePath: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('uploadLibrary', 'relativePath', relativePath)
            const localVarPath = `/atlas/library`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (relativePath !== undefined) {
                localVarQueryParameter['relativePath'] = relativePath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {AtlasMapping} atlasMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMappingRequest: async (mappingDefinitionId: number, atlasMapping: AtlasMapping, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mappingDefinitionId' is not null or undefined
            assertParamExists('validateMappingRequest', 'mappingDefinitionId', mappingDefinitionId)
            // verify required parameter 'atlasMapping' is not null or undefined
            assertParamExists('validateMappingRequest', 'atlasMapping', atlasMapping)
            const localVarPath = `/atlas/mapping/validate/{mappingDefinitionId}`
                .replace(`{${"mappingDefinitionId"}}`, encodeURIComponent(String(mappingDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atlasMapping, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/atlas/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AtlasControllerApi - functional programming interface
 * @export
 */
export const AtlasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AtlasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MappingFileType} mappingFormat 
         * @param {number} mappingDefinitionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMappingRequest(mappingFormat, mappingDefinitionId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.createMappingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAdmFile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAdmFile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.downloadAdmFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldActions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldActions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.fieldActions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MappingFileType} mappingFormat 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMappingRequest(mappingFormat, mappingDefinitionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.getMappingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} format 
         * @param {string} delimiter 
         * @param {boolean} firstRecordAsHeader 
         * @param {boolean} skipHeaderRecord 
         * @param {string} headers 
         * @param {string} commentMarker 
         * @param {string} escape 
         * @param {boolean} ignoreEmptyLines 
         * @param {boolean} ignoreHeaderCase 
         * @param {boolean} ignoreSurroundingSpaces 
         * @param {string} nullString 
         * @param {string} quote 
         * @param {boolean} allowDuplicateHeaderNames 
         * @param {boolean} allowMissingColumnNames 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspectCsv(format: string, delimiter: string, firstRecordAsHeader: boolean, skipHeaderRecord: boolean, headers: string, commentMarker: string, escape: string, ignoreEmptyLines: boolean, ignoreHeaderCase: boolean, ignoreSurroundingSpaces: boolean, nullString: string, quote: string, allowDuplicateHeaderNames: boolean, allowMissingColumnNames: boolean, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspectCsv(format, delimiter, firstRecordAsHeader, skipHeaderRecord, headers, commentMarker, escape, ignoreEmptyLines, ignoreHeaderCase, ignoreSurroundingSpaces, nullString, quote, allowDuplicateHeaderNames, allowMissingColumnNames, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.inspectCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Hl7V2InspectionRequest} hl7V2InspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspectHl7v2(hl7V2InspectionRequest: Hl7V2InspectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspectHl7v2(hl7V2InspectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.inspectHl7v2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ClassInspectionRequest} classInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspectJava(classInspectionRequest: ClassInspectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspectJava(classInspectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.inspectJava']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {JsonInspectionRequest} jsonInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspectJson(jsonInspectionRequest: JsonInspectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspectJson(jsonInspectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.inspectJson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {X12InspectionRequest} x12InspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspectX12(x12InspectionRequest: X12InspectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspectX12(x12InspectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.inspectX12']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {XmlInspectionRequest} xmlInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspectXml(xmlInspectionRequest: XmlInspectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspectXml(xmlInspectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.inspectXml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLibraryClasses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLibraryClasses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.listLibraryClasses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMappingBuilderClasses(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMappingBuilderClasses(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.listMappingBuilderClasses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} filter 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMappings(filter: string, mappingDefinitionId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMappings(filter, mappingDefinitionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.listMappings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAdmFile(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processAdmFile(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.processAdmFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProcessMappingRequest} processMappingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMappingRequest(processMappingRequest: ProcessMappingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processMappingRequest(processMappingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.processMappingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshFieldActions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshFieldActions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.refreshFieldActions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMappingRequest(mappingDefinitionId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMappingRequest(mappingDefinitionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.removeMappingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAllMappings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetAllMappings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.resetAllMappings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetMappingById(mappingDefinitionId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetMappingById(mappingDefinitionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.resetMappingById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMappingRequest(mappingDefinitionId: number, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMappingRequest(mappingDefinitionId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.updateMappingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLibrary(relativePath: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLibrary(relativePath, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.uploadLibrary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {AtlasMapping} atlasMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateMappingRequest(mappingDefinitionId: number, atlasMapping: AtlasMapping, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateMappingRequest(mappingDefinitionId, atlasMapping, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.validateMappingRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async version(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.version(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AtlasControllerApi.version']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AtlasControllerApi - factory interface
 * @export
 */
export const AtlasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AtlasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MappingFileType} mappingFormat 
         * @param {number} mappingDefinitionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.createMappingRequest(mappingFormat, mappingDefinitionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAdmFile(options?: any): AxiosPromise<File> {
            return localVarFp.downloadAdmFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldActions(options?: any): AxiosPromise<string> {
            return localVarFp.fieldActions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MappingFileType} mappingFormat 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, options?: any): AxiosPromise<File> {
            return localVarFp.getMappingRequest(mappingFormat, mappingDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} format 
         * @param {string} delimiter 
         * @param {boolean} firstRecordAsHeader 
         * @param {boolean} skipHeaderRecord 
         * @param {string} headers 
         * @param {string} commentMarker 
         * @param {string} escape 
         * @param {boolean} ignoreEmptyLines 
         * @param {boolean} ignoreHeaderCase 
         * @param {boolean} ignoreSurroundingSpaces 
         * @param {string} nullString 
         * @param {string} quote 
         * @param {boolean} allowDuplicateHeaderNames 
         * @param {boolean} allowMissingColumnNames 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectCsv(format: string, delimiter: string, firstRecordAsHeader: boolean, skipHeaderRecord: boolean, headers: string, commentMarker: string, escape: string, ignoreEmptyLines: boolean, ignoreHeaderCase: boolean, ignoreSurroundingSpaces: boolean, nullString: string, quote: string, allowDuplicateHeaderNames: boolean, allowMissingColumnNames: boolean, body: string, options?: any): AxiosPromise<string> {
            return localVarFp.inspectCsv(format, delimiter, firstRecordAsHeader, skipHeaderRecord, headers, commentMarker, escape, ignoreEmptyLines, ignoreHeaderCase, ignoreSurroundingSpaces, nullString, quote, allowDuplicateHeaderNames, allowMissingColumnNames, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Hl7V2InspectionRequest} hl7V2InspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectHl7v2(hl7V2InspectionRequest: Hl7V2InspectionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.inspectHl7v2(hl7V2InspectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClassInspectionRequest} classInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectJava(classInspectionRequest: ClassInspectionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.inspectJava(classInspectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {JsonInspectionRequest} jsonInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectJson(jsonInspectionRequest: JsonInspectionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.inspectJson(jsonInspectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {X12InspectionRequest} x12InspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectX12(x12InspectionRequest: X12InspectionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.inspectX12(x12InspectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {XmlInspectionRequest} xmlInspectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectXml(xmlInspectionRequest: XmlInspectionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.inspectXml(xmlInspectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLibraryClasses(options?: any): AxiosPromise<string> {
            return localVarFp.listLibraryClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappingBuilderClasses(options?: any): AxiosPromise<string> {
            return localVarFp.listMappingBuilderClasses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filter 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMappings(filter: string, mappingDefinitionId: number, options?: any): AxiosPromise<string> {
            return localVarFp.listMappings(filter, mappingDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdmFile(file: File, options?: any): AxiosPromise<File> {
            return localVarFp.processAdmFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessMappingRequest} processMappingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMappingRequest(processMappingRequest: ProcessMappingRequest, options?: any): AxiosPromise<string> {
            return localVarFp.processMappingRequest(processMappingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshFieldActions(options?: any): AxiosPromise<void> {
            return localVarFp.refreshFieldActions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMappingRequest(mappingDefinitionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeMappingRequest(mappingDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAllMappings(options?: any): AxiosPromise<void> {
            return localVarFp.resetAllMappings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetMappingById(mappingDefinitionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.resetMappingById(mappingDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMappingRequest(mappingDefinitionId: number, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateMappingRequest(mappingDefinitionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLibrary(relativePath: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadLibrary(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mappingDefinitionId 
         * @param {AtlasMapping} atlasMapping 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateMappingRequest(mappingDefinitionId: number, atlasMapping: AtlasMapping, options?: any): AxiosPromise<string> {
            return localVarFp.validateMappingRequest(mappingDefinitionId, atlasMapping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options?: any): AxiosPromise<string> {
            return localVarFp.version(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AtlasControllerApi - interface
 * @export
 * @interface AtlasControllerApi
 */
export interface AtlasControllerApiInterface {
    /**
     * 
     * @param {MappingFileType} mappingFormat 
     * @param {number} mappingDefinitionId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    createMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, body: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    downloadAdmFile(options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    fieldActions(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {MappingFileType} mappingFormat 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    getMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} format 
     * @param {string} delimiter 
     * @param {boolean} firstRecordAsHeader 
     * @param {boolean} skipHeaderRecord 
     * @param {string} headers 
     * @param {string} commentMarker 
     * @param {string} escape 
     * @param {boolean} ignoreEmptyLines 
     * @param {boolean} ignoreHeaderCase 
     * @param {boolean} ignoreSurroundingSpaces 
     * @param {string} nullString 
     * @param {string} quote 
     * @param {boolean} allowDuplicateHeaderNames 
     * @param {boolean} allowMissingColumnNames 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    inspectCsv(format: string, delimiter: string, firstRecordAsHeader: boolean, skipHeaderRecord: boolean, headers: string, commentMarker: string, escape: string, ignoreEmptyLines: boolean, ignoreHeaderCase: boolean, ignoreSurroundingSpaces: boolean, nullString: string, quote: string, allowDuplicateHeaderNames: boolean, allowMissingColumnNames: boolean, body: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {Hl7V2InspectionRequest} hl7V2InspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    inspectHl7v2(hl7V2InspectionRequest: Hl7V2InspectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {ClassInspectionRequest} classInspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    inspectJava(classInspectionRequest: ClassInspectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {JsonInspectionRequest} jsonInspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    inspectJson(jsonInspectionRequest: JsonInspectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {X12InspectionRequest} x12InspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    inspectX12(x12InspectionRequest: X12InspectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {XmlInspectionRequest} xmlInspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    inspectXml(xmlInspectionRequest: XmlInspectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    listLibraryClasses(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    listMappingBuilderClasses(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} filter 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    listMappings(filter: string, mappingDefinitionId: number, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    processAdmFile(file: File, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {ProcessMappingRequest} processMappingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    processMappingRequest(processMappingRequest: ProcessMappingRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    refreshFieldActions(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    removeMappingRequest(mappingDefinitionId: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    resetAllMappings(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    resetMappingById(mappingDefinitionId: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    updateMappingRequest(mappingDefinitionId: number, body: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    uploadLibrary(relativePath: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {AtlasMapping} atlasMapping 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    validateMappingRequest(mappingDefinitionId: number, atlasMapping: AtlasMapping, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApiInterface
     */
    version(options?: RawAxiosRequestConfig): AxiosPromise<string>;

}

/**
 * AtlasControllerApi - object-oriented interface
 * @export
 * @class AtlasControllerApi
 * @extends {BaseAPI}
 */
export class AtlasControllerApi extends BaseAPI implements AtlasControllerApiInterface {
    /**
     * 
     * @param {MappingFileType} mappingFormat 
     * @param {number} mappingDefinitionId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public createMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, body: string, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).createMappingRequest(mappingFormat, mappingDefinitionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public downloadAdmFile(options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).downloadAdmFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public fieldActions(options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).fieldActions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MappingFileType} mappingFormat 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public getMappingRequest(mappingFormat: MappingFileType, mappingDefinitionId: number, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).getMappingRequest(mappingFormat, mappingDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} format 
     * @param {string} delimiter 
     * @param {boolean} firstRecordAsHeader 
     * @param {boolean} skipHeaderRecord 
     * @param {string} headers 
     * @param {string} commentMarker 
     * @param {string} escape 
     * @param {boolean} ignoreEmptyLines 
     * @param {boolean} ignoreHeaderCase 
     * @param {boolean} ignoreSurroundingSpaces 
     * @param {string} nullString 
     * @param {string} quote 
     * @param {boolean} allowDuplicateHeaderNames 
     * @param {boolean} allowMissingColumnNames 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public inspectCsv(format: string, delimiter: string, firstRecordAsHeader: boolean, skipHeaderRecord: boolean, headers: string, commentMarker: string, escape: string, ignoreEmptyLines: boolean, ignoreHeaderCase: boolean, ignoreSurroundingSpaces: boolean, nullString: string, quote: string, allowDuplicateHeaderNames: boolean, allowMissingColumnNames: boolean, body: string, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).inspectCsv(format, delimiter, firstRecordAsHeader, skipHeaderRecord, headers, commentMarker, escape, ignoreEmptyLines, ignoreHeaderCase, ignoreSurroundingSpaces, nullString, quote, allowDuplicateHeaderNames, allowMissingColumnNames, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Hl7V2InspectionRequest} hl7V2InspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public inspectHl7v2(hl7V2InspectionRequest: Hl7V2InspectionRequest, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).inspectHl7v2(hl7V2InspectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClassInspectionRequest} classInspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public inspectJava(classInspectionRequest: ClassInspectionRequest, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).inspectJava(classInspectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JsonInspectionRequest} jsonInspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public inspectJson(jsonInspectionRequest: JsonInspectionRequest, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).inspectJson(jsonInspectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {X12InspectionRequest} x12InspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public inspectX12(x12InspectionRequest: X12InspectionRequest, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).inspectX12(x12InspectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {XmlInspectionRequest} xmlInspectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public inspectXml(xmlInspectionRequest: XmlInspectionRequest, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).inspectXml(xmlInspectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public listLibraryClasses(options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).listLibraryClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public listMappingBuilderClasses(options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).listMappingBuilderClasses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} filter 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public listMappings(filter: string, mappingDefinitionId: number, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).listMappings(filter, mappingDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public processAdmFile(file: File, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).processAdmFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessMappingRequest} processMappingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public processMappingRequest(processMappingRequest: ProcessMappingRequest, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).processMappingRequest(processMappingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public refreshFieldActions(options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).refreshFieldActions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public removeMappingRequest(mappingDefinitionId: number, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).removeMappingRequest(mappingDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public resetAllMappings(options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).resetAllMappings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public resetMappingById(mappingDefinitionId: number, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).resetMappingById(mappingDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public updateMappingRequest(mappingDefinitionId: number, body: string, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).updateMappingRequest(mappingDefinitionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public uploadLibrary(relativePath: string, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).uploadLibrary(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mappingDefinitionId 
     * @param {AtlasMapping} atlasMapping 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public validateMappingRequest(mappingDefinitionId: number, atlasMapping: AtlasMapping, options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).validateMappingRequest(mappingDefinitionId, atlasMapping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtlasControllerApi
     */
    public version(options?: RawAxiosRequestConfig) {
        return AtlasControllerApiFp(this.configuration).version(options).then((request) => request(this.axios, this.basePath));
    }
}

