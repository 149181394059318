/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description NavigationBar
 */

import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Button, Layout, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { DEFAULT_APP_NAME } from 'constants/defaults';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { selectedMenuKey } from 'utils/common';
import { useAppActions, useAppValues } from 'store/app';
import { useNavigationActions, useNavigationValues } from 'store/navigation';

const NavigationBar: React.FC = () => {
	const navigate = useNavigate();
	const param = useParams();

	const { webConfig, collapsed } = useAppValues();
	const { selectedKeys } = useNavigationValues();
	const { setSelectedKeys } = useNavigationActions();
	const { setCollapsed } = useAppActions();

	const buildItems = (configList: typeof webConfig): ItemType<MenuItemType>[] =>
		_.map(configList, (item) => {
			const label = _.isString(item.title) ? item.title : (item.title?.[0]?.text ?? DEFAULT_APP_NAME);
			const children = item.children ? buildItems(item.children) : undefined;
			return {
				..._.omit(item, ['title', 'children']),
				label,
				onClick: () => (item.url ? navigate(item.url) : undefined),
				children,
			};
		});

	const items: ItemType<MenuItemType>[] = useMemo(() => buildItems(webConfig), [webConfig]);

	useEffect(() => {
		setSelectedKeys([selectedMenuKey(param, webConfig, 'search')]);
	}, [param]);

	const onCollapseChange = () => {
		setCollapsed(!collapsed);
	};

	return (
		<Layout.Sider
			className='main-layout-sider'
			collapsedWidth={56}
			width={184}
			trigger={null}
			collapsible
			collapsed={collapsed}
		>
			{/* HIM User Menu */}
			<Menu style={{ marginBlock: 15 }} selectedKeys={selectedKeys} mode='inline' items={items} />

			<div style={{ marginTop: 'auto' }} className='full-width'>
				{/* Settings Menu Item */}
				<Menu
					mode='inline'
					items={[
						{
							key: 'settings',
							icon: <SettingOutlined style={{ fontSize: 15 }} />,
							label: 'Settings',
							title: 'Settings',
							onClick: () => navigate('/settings/*'),
						},
					]}
				/>

				{/* Collapse Trigger */}
				<div className='full-width' style={{ padding: '12px 11px', borderTop: '1px solid #E5E7EB' }}>
					<Button
						type='text'
						icon={
							collapsed ? (
								<MenuUnfoldOutlined style={{ color: '#9098A7' }} />
							) : (
								<MenuFoldOutlined style={{ color: '#9098A7' }} />
							)
						}
						onClick={onCollapseChange}
					/>
				</div>
			</div>
		</Layout.Sider>
	);
};

export default NavigationBar;
