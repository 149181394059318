/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Select a Queue with Create a queue option
 */

import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import log from 'loglevel';
import { Button, Divider, Form, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SelectProps } from 'antd/lib';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import HasAccess from 'utils/HasAccess';
import useCreateQueue from 'hooks/mutations/useCreateQueue';
import useQueue from 'hooks/useQueue';
import { ROLES } from 'constants/roles';
import { Stages, QueueRequestModel, ResponseModel } from '@dexit/common/openapi';
import { commitStageName, initialStageName } from 'components/manage-queues/QueuesIndexingStages';
import { useUserValues } from 'store/user';

interface IProps {
	formItemName: string;
}
const QUERY_QUEUE_WITH_CREATE = 'query-queue-with-create';

const SelectQueueWithCreate: React.FC<IProps & SelectProps> = (props) => {
	const { formItemName, ...restProps } = props;
	const queryClient = useQueryClient();
	const form = Form.useFormInstance();

	const { user } = useUserValues();
	const [queueName, setQueueName] = useState<string>();
	const [selectOpen, setSelectOpen] = useState(false);

	const { data, isFetching } = useQueue(true, {
		enabled: !restProps?.options,
		queryKey: [QUERY_QUEUE_WITH_CREATE],
	});
	const createQueue = useCreateQueue();

	const queueOptions = useMemo(
		() => restProps?.options ?? _.map(data, (queue) => ({ value: queue.id, label: queue.name })),
		[data],
	);

	const status = useMemo(() => (_.find(queueOptions, { label: queueName }) ? 'error' : undefined), [queueName]);

	const handleCreateQueue = () => {
		if (!user?.id) {
			throw new Error('User Id not found');
		}
		const todoStage: Stages = {
			id: uuidv4(),
			name: initialStageName,
			sort_index: 1,
			icon_color: '#BFBFBF',
			issystem: true,
			iscommit: false,
		};
		const commitStage: Stages = {
			id: uuidv4(),
			name: commitStageName,
			sort_index: 2,
			icon_color: '#1890FF',
			issystem: true,
			iscommit: true,
		};
		const payload: QueueRequestModel = {
			name: queueName,
			added_users: [user?.id],
			deleted_users: [],
			stages: [todoStage, commitStage],
		};
		createQueue.mutate(payload, {
			onSuccess: (responseData: ResponseModel) => {
				form.setFieldsValue({ [formItemName]: responseData.id });
				setSelectOpen(false);
				setQueueName(undefined);
				queryClient.invalidateQueries([QUERY_QUEUE_WITH_CREATE]).catch((err) => log.error(err));
			},
		});
	};

	const dropdownRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
		<div>
			<div>{menu}</div>
			<HasAccess roles={[ROLES.manageQueues]}>
				<Divider style={{ margin: '8px 0' }} />
				<div style={{ marginRight: 0, padding: '5px', float: 'right', gap: 8 }} className='flex-space-between'>
					<Input
						placeholder='Enter Queue Name'
						status={_.find(queueOptions, { label: queueName }) ? 'error' : undefined}
						style={{ flex: 1 }}
						value={queueName}
						onChange={(e) => setQueueName(e.target.value)}
					/>

					<Button
						type='text'
						icon={<PlusOutlined />}
						disabled={status === 'error' || !queueName}
						style={{ minWidth: 'auto' }}
						loading={createQueue.isLoading}
						onClick={handleCreateQueue}
					>
						Add New
					</Button>
				</div>
			</HasAccess>
		</div>
	);

	return (
		<Select
			allowClear
			open={selectOpen}
			onDropdownVisibleChange={() => setSelectOpen(!selectOpen)}
			showSearch
			placeholder='Select Queue'
			loading={isFetching}
			options={queueOptions}
			optionFilterProp='label'
			filterOption={(input, option) =>
				_.lowerCase(typeof option?.label === 'string' ? option.label : undefined).includes(
					input.toLowerCase(),
				) ?? false
			}
			dropdownRender={(menu) => dropdownRender(menu)}
			{...restProps}
		/>
	);
};

export default SelectQueueWithCreate;
