/* tslint:disable */
/* eslint-disable */
/**
 * Zsegment
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ArchiveMessage } from '../Models';
// @ts-ignore
import { CreateIndex200Response } from '../Models';
// @ts-ignore
import { ErrorResponse } from '../Models';
// @ts-ignore
import { GetIndexes200Response } from '../Models';
// @ts-ignore
import { GetMessage200Response } from '../Models';
// @ts-ignore
import { GetSearch200Response } from '../Models';
// @ts-ignore
import { KeepAlive200Response } from '../Models';
// @ts-ignore
import { MessageIndex } from '../Models';
// @ts-ignore
import { MessageSearchInput } from '../Models';
// @ts-ignore
import { MimeType } from '../Models';
// @ts-ignore
import { Query } from '../Models';
// @ts-ignore
import { SaveSearch200Response } from '../Models';
// @ts-ignore
import { SearchField200Response } from '../Models';
// @ts-ignore
import { SearchMessages200Response } from '../Models';
// @ts-ignore
import { SubmitDestinationMessageRequest } from '../Models';
// @ts-ignore
import { SubmitSourceMessageRequest } from '../Models';
/**
 * MessageControllerApi - axios parameter creator
 * @export
 */
export const MessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ArchiveMessage} archiveMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive: async (archiveMessage: ArchiveMessage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archiveMessage' is not null or undefined
            assertParamExists('archive', 'archiveMessage', archiveMessage)
            const localVarPath = `/message/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageIndex} messageIndex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndex: async (messageIndex: MessageIndex, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageIndex' is not null or undefined
            assertParamExists('createIndex', 'messageIndex', messageIndex)
            const localVarPath = `/message/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageIndex, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSearchById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSearchById', 'id', id)
            const localVarPath = `/message/search/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MimeType} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexes: async (mimeType?: MimeType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (mimeType !== undefined) {
                localVarQueryParameter['mimeType'] = mimeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage: async (messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getMessage', 'messageId', messageId)
            const localVarPath = `/message/search/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} includeAll 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearch: async (includeAll: boolean, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'includeAll' is not null or undefined
            assertParamExists('getSearch', 'includeAll', includeAll)
            const localVarPath = `/message/search/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (includeAll !== undefined) {
                localVarQueryParameter['includeAll'] = includeAll;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (sessionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getSession', 'sessionId', sessionId)
            const localVarPath = `/message/search/session/{session_id}`
                .replace(`{${"session_id"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} indexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIndex: async (indexId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indexId' is not null or undefined
            assertParamExists('removeIndex', 'indexId', indexId)
            const localVarPath = `/message/index/{index_id}`
                .replace(`{${"index_id"}}`, encodeURIComponent(String(indexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageSearchInput} messageSearchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSearch: async (messageSearchInput: MessageSearchInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageSearchInput' is not null or undefined
            assertParamExists('saveSearch', 'messageSearchInput', messageSearchInput)
            const localVarPath = `/message/search/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageSearchInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchField: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Query} query 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMessages: async (query: Query, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchMessages', 'query', query)
            const localVarPath = `/message/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(query, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitDestinationMessageRequest} submitDestinationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDestinationMessage: async (submitDestinationMessageRequest: SubmitDestinationMessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitDestinationMessageRequest' is not null or undefined
            assertParamExists('submitDestinationMessage', 'submitDestinationMessageRequest', submitDestinationMessageRequest)
            const localVarPath = `/message/submit/destination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitDestinationMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitSourceMessageRequest} submitSourceMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSourceMessage: async (submitSourceMessageRequest: SubmitSourceMessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitSourceMessageRequest' is not null or undefined
            assertParamExists('submitSourceMessage', 'submitSourceMessageRequest', submitSourceMessageRequest)
            const localVarPath = `/message/submit/source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitSourceMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MessageSearchInput} messageSearchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearch: async (id: string, messageSearchInput: MessageSearchInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSearch', 'id', id)
            // verify required parameter 'messageSearchInput' is not null or undefined
            assertParamExists('updateSearch', 'messageSearchInput', messageSearchInput)
            const localVarPath = `/message/search/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageSearchInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageControllerApi - functional programming interface
 * @export
 */
export const MessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ArchiveMessage} archiveMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archive(archiveMessage: ArchiveMessage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeepAlive200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archive(archiveMessage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.archive']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MessageIndex} messageIndex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIndex(messageIndex: MessageIndex, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateIndex200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIndex(messageIndex, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.createIndex']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSearchById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSearch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSearchById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.deleteSearchById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MimeType} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndexes(mimeType?: MimeType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIndexes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndexes(mimeType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.getIndexes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessage(messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMessage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessage(messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.getMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} includeAll 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearch(includeAll: boolean, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSearch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearch(includeAll, pageNumber, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.getSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(sessionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchMessages200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.getSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} indexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeIndex(indexId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeepAlive200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeIndex(indexId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.removeIndex']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MessageSearchInput} messageSearchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSearch(messageSearchInput: MessageSearchInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveSearch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSearch(messageSearchInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.saveSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchField(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchField200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchField(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.searchField']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Query} query 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMessages(query: Query, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchMessages200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMessages(query, pageNumber, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.searchMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SubmitDestinationMessageRequest} submitDestinationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitDestinationMessage(submitDestinationMessageRequest: SubmitDestinationMessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeepAlive200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitDestinationMessage(submitDestinationMessageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.submitDestinationMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SubmitSourceMessageRequest} submitSourceMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitSourceMessage(submitSourceMessageRequest: SubmitSourceMessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeepAlive200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitSourceMessage(submitSourceMessageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.submitSourceMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {MessageSearchInput} messageSearchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSearch(id: string, messageSearchInput: MessageSearchInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveSearch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSearch(id, messageSearchInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessageControllerApi.updateSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MessageControllerApi - factory interface
 * @export
 */
export const MessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ArchiveMessage} archiveMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(archiveMessage: ArchiveMessage, options?: any): AxiosPromise<KeepAlive200Response> {
            return localVarFp.archive(archiveMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessageIndex} messageIndex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIndex(messageIndex: MessageIndex, options?: any): AxiosPromise<CreateIndex200Response> {
            return localVarFp.createIndex(messageIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSearchById(id: string, options?: any): AxiosPromise<GetSearch200Response> {
            return localVarFp.deleteSearchById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MimeType} [mimeType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndexes(mimeType?: MimeType, options?: any): AxiosPromise<GetIndexes200Response> {
            return localVarFp.getIndexes(mimeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage(messageId: string, options?: any): AxiosPromise<GetMessage200Response> {
            return localVarFp.getMessage(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} includeAll 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearch(includeAll: boolean, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<GetSearch200Response> {
            return localVarFp.getSearch(includeAll, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(sessionId: string, options?: any): AxiosPromise<SearchMessages200Response> {
            return localVarFp.getSession(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} indexId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIndex(indexId: string, options?: any): AxiosPromise<KeepAlive200Response> {
            return localVarFp.removeIndex(indexId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessageSearchInput} messageSearchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSearch(messageSearchInput: MessageSearchInput, options?: any): AxiosPromise<SaveSearch200Response> {
            return localVarFp.saveSearch(messageSearchInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchField(options?: any): AxiosPromise<SearchField200Response> {
            return localVarFp.searchField(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Query} query 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMessages(query: Query, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<SearchMessages200Response> {
            return localVarFp.searchMessages(query, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitDestinationMessageRequest} submitDestinationMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDestinationMessage(submitDestinationMessageRequest: SubmitDestinationMessageRequest, options?: any): AxiosPromise<KeepAlive200Response> {
            return localVarFp.submitDestinationMessage(submitDestinationMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitSourceMessageRequest} submitSourceMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSourceMessage(submitSourceMessageRequest: SubmitSourceMessageRequest, options?: any): AxiosPromise<KeepAlive200Response> {
            return localVarFp.submitSourceMessage(submitSourceMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MessageSearchInput} messageSearchInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearch(id: string, messageSearchInput: MessageSearchInput, options?: any): AxiosPromise<SaveSearch200Response> {
            return localVarFp.updateSearch(id, messageSearchInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageControllerApi - interface
 * @export
 * @interface MessageControllerApi
 */
export interface MessageControllerApiInterface {
    /**
     * 
     * @param {ArchiveMessage} archiveMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    archive(archiveMessage: ArchiveMessage, options?: RawAxiosRequestConfig): AxiosPromise<KeepAlive200Response>;

    /**
     * 
     * @param {MessageIndex} messageIndex 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    createIndex(messageIndex: MessageIndex, options?: RawAxiosRequestConfig): AxiosPromise<CreateIndex200Response>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    deleteSearchById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GetSearch200Response>;

    /**
     * 
     * @param {MimeType} [mimeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    getIndexes(mimeType?: MimeType, options?: RawAxiosRequestConfig): AxiosPromise<GetIndexes200Response>;

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    getMessage(messageId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetMessage200Response>;

    /**
     * 
     * @param {boolean} includeAll 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    getSearch(includeAll: boolean, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetSearch200Response>;

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    getSession(sessionId: string, options?: RawAxiosRequestConfig): AxiosPromise<SearchMessages200Response>;

    /**
     * 
     * @param {string} indexId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    removeIndex(indexId: string, options?: RawAxiosRequestConfig): AxiosPromise<KeepAlive200Response>;

    /**
     * 
     * @param {MessageSearchInput} messageSearchInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    saveSearch(messageSearchInput: MessageSearchInput, options?: RawAxiosRequestConfig): AxiosPromise<SaveSearch200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    searchField(options?: RawAxiosRequestConfig): AxiosPromise<SearchField200Response>;

    /**
     * 
     * @param {Query} query 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    searchMessages(query: Query, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<SearchMessages200Response>;

    /**
     * 
     * @param {SubmitDestinationMessageRequest} submitDestinationMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    submitDestinationMessage(submitDestinationMessageRequest: SubmitDestinationMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KeepAlive200Response>;

    /**
     * 
     * @param {SubmitSourceMessageRequest} submitSourceMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    submitSourceMessage(submitSourceMessageRequest: SubmitSourceMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KeepAlive200Response>;

    /**
     * 
     * @param {string} id 
     * @param {MessageSearchInput} messageSearchInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApiInterface
     */
    updateSearch(id: string, messageSearchInput: MessageSearchInput, options?: RawAxiosRequestConfig): AxiosPromise<SaveSearch200Response>;

}

/**
 * MessageControllerApi - object-oriented interface
 * @export
 * @class MessageControllerApi
 * @extends {BaseAPI}
 */
export class MessageControllerApi extends BaseAPI implements MessageControllerApiInterface {
    /**
     * 
     * @param {ArchiveMessage} archiveMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public archive(archiveMessage: ArchiveMessage, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).archive(archiveMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageIndex} messageIndex 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public createIndex(messageIndex: MessageIndex, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).createIndex(messageIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public deleteSearchById(id: string, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).deleteSearchById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MimeType} [mimeType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getIndexes(mimeType?: MimeType, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getIndexes(mimeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getMessage(messageId: string, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getMessage(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} includeAll 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getSearch(includeAll: boolean, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getSearch(includeAll, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getSession(sessionId: string, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getSession(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} indexId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public removeIndex(indexId: string, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).removeIndex(indexId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageSearchInput} messageSearchInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public saveSearch(messageSearchInput: MessageSearchInput, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).saveSearch(messageSearchInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public searchField(options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).searchField(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Query} query 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public searchMessages(query: Query, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).searchMessages(query, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitDestinationMessageRequest} submitDestinationMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public submitDestinationMessage(submitDestinationMessageRequest: SubmitDestinationMessageRequest, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).submitDestinationMessage(submitDestinationMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitSourceMessageRequest} submitSourceMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public submitSourceMessage(submitSourceMessageRequest: SubmitSourceMessageRequest, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).submitSourceMessage(submitSourceMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MessageSearchInput} messageSearchInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public updateSearch(id: string, messageSearchInput: MessageSearchInput, options?: RawAxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).updateSearch(id, messageSearchInput, options).then((request) => request(this.axios, this.basePath));
    }
}

