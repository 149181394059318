/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Form for Batch
 */

import React, { useEffect } from 'react';
import _ from 'lodash';
import { Form, UploadFile } from 'antd';

import SelectQueueWithCreate from 'components/queues/SelectQueueWithCreate';

interface IProps {
	queueId?: string;
	fileList?: UploadFile[];
}
const BatchForm: React.FC<IProps> = (props) => {
	const { queueId, fileList } = props;
	const form = Form.useFormInstance();

	useEffect(() => {
		form.setFieldValue('queue', queueId);
	}, [queueId]);

	return (
		<Form.Item name='queue' label='Queue Name' rules={[{ required: true, message: 'Please select a Queue' }]}>
			<SelectQueueWithCreate formItemName='queue' disabled={!!queueId || (fileList && _.isEmpty(fileList))} />
		</Form.Item>
	);
};

export default BatchForm;
