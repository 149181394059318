/**
 * @author Sanjay <sanjay.krishnamurthy@314ecorp.com>
 * @description common
 */

import { getBasePath } from '@dexit/common/utils/url';
import _ from 'lodash';

const findMenuItemKey = (items: MenuItem[], key?: string): string | undefined => {
	for (const item of items) {
		if (item.key === key) {
			return item.key;
		}
		if (item.children) {
			const foundKey = findMenuItemKey(item.children, key);
			if (foundKey) {
				return foundKey;
			}
		}
	}
	return undefined;
};

const selectedMenuKey = (
	param: Record<string, string | undefined>,
	menuItems: MenuItem[],
	defaultKey: string,
): string => {
	const url = _.first(_.filter(_.split(_.get(param, '*'), '/'), (item) => item !== getBasePath()));
	const menuKey = findMenuItemKey(menuItems, url);

	return menuKey || defaultKey;
};

export { selectedMenuKey };
