/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description PDFJS PDFViewer
 */

import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, InputNumber, List, Row, Spin } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

import RenderPdf from './RenderPdf';
import { pdfJS, getPdfWorker } from 'utils/pdfConfig';

interface IProps {
	url?: string;
}

const PDFJSViewer: React.FC<IProps> = (props) => {
	const { url } = props;
	const isScaleUpdated = useRef(false);

	const [pdf, setPdf] = useState<pdfJS.PDFDocumentProxy>();
	const [loading, setLoading] = useState(true);
	const [pageToView, setPageToView] = useState<{ pageNum: number; scroll: boolean }>({
		pageNum: 1,
		scroll: false,
	});
	const [pdfRenderScale, setPdfRenderScale] = useState<number>(1);

	const loadFile = async (path: string) => {
		setLoading(true);
		const data = await fetch(path).then((res) => res.arrayBuffer());
		const pdfbytes: pdfJS.PDFDocumentProxy = await pdfJS.getDocument({
			data,
			worker: getPdfWorker(),
		}).promise;
		setPdf(pdfbytes);
		setLoading(false);
	};

	const updateScale = async (pdf: any) => {
		const container = document.getElementById('pdf-viewer-list');
		if (!container) {
			return;
		}
		const page = await pdf.getPage(1);
		const pageWidthScale = container.clientWidth / page.view[2];
		const pageHeightScale = container.clientHeight / page.view[3];
		const newScale = Math.min(pageWidthScale, pageHeightScale);
		setPdfRenderScale(newScale);
	};

	useEffect(() => {
		if (pdf && !isScaleUpdated.current) {
			void updateScale(pdf);
			isScaleUpdated.current = true;
		}
	}, [pdf, pdfRenderScale]);

	useEffect(() => {
		if (url) {
			void loadFile(url);
		}
	}, [url]);

	const zoomIn = () => {
		if (pdfRenderScale > 1.5) {
			return;
		}
		setPdfRenderScale(pdfRenderScale + 0.1);
	};

	const zoomOut = () => {
		if (pdfRenderScale < 0.3) {
			return;
		}
		setPdfRenderScale(pdfRenderScale - 0.1);
	};

	if (loading) {
		return <Spin />;
	}
	if (!pdf) {
		return null;
	}
	return (
		<div className='flex-column full-width-height'>
			<Row className='flex-align-center full-width' style={{ height: '40px' }}>
				<div className='flex-center' style={{ flex: '1 1 0%' }}>
					<Button shape='circle' type='text' icon={<ZoomInOutlined />} onClick={zoomIn} />
					<Button shape='circle' type='text' icon={<ZoomOutOutlined />} onClick={zoomOut} />
					<div>
						<InputNumber
							style={{ width: '50px', marginRight: '8px', height: '30px' }}
							onPressEnter={(event: any) => {
								const value = _.parseInt(event.target.value);
								setPageToView({ pageNum: value, scroll: false });
							}}
							value={pageToView.pageNum}
							controls={false}
						/>
						/ {pdf.numPages}
					</div>
				</div>
			</Row>
			<Row
				className='flex-center'
				id='pdf-viewer-list'
				style={{ flex: 1, overflowY: 'scroll', background: '#999999' }}
			>
				{_.map(_.range(pdf?.numPages), (pageNum) => (
					<List.Item
						key={pageNum + 1}
						className='flex-justify-center'
						style={{ border: 'none', backgroundColor: '#999999' }}
					>
						<RenderPdf
							id={(pageNum + 1).toString()}
							pageNum={pageNum + 1}
							pdf={pdf}
							pageToView={pageToView}
							pdfRenderScale={pdfRenderScale}
							setPageToView={(pageNum: number, scroll: boolean) => {
								setPageToView({ pageNum, scroll });
								return { pageNum, scroll };
							}}
						/>
					</List.Item>
				))}
			</Row>
		</div>
	);
};

export default PDFJSViewer;
