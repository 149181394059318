/* tslint:disable */
/* eslint-disable */
/**
 * Zsegment
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AddInterface200Response } from '../Models';
// @ts-ignore
import { ErrorResponse } from '../Models';
// @ts-ignore
import { GetInterfaces200Response } from '../Models';
// @ts-ignore
import { Interfacee } from '../Models';
// @ts-ignore
import { KeepAlive200Response } from '../Models';
/**
 * InterfaceControllerApi - axios parameter creator
 * @export
 */
export const InterfaceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Interfacee} interfacee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInterface: async (interfacee: Interfacee, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interfacee' is not null or undefined
            assertParamExists('addInterface', 'interfacee', interfacee)
            const localVarPath = `/interfaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interfacee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} interfaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterface: async (interfaceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interfaceId' is not null or undefined
            assertParamExists('deleteInterface', 'interfaceId', interfaceId)
            const localVarPath = `/interfaces/{interface_id}`
                .replace(`{${"interface_id"}}`, encodeURIComponent(String(interfaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaces: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/interfaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} interfaceId 
         * @param {Interfacee} interfacee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterface: async (interfaceId: string, interfacee: Interfacee, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interfaceId' is not null or undefined
            assertParamExists('updateInterface', 'interfaceId', interfaceId)
            // verify required parameter 'interfacee' is not null or undefined
            assertParamExists('updateInterface', 'interfacee', interfacee)
            const localVarPath = `/interfaces/{interface_id}`
                .replace(`{${"interface_id"}}`, encodeURIComponent(String(interfaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interfacee, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterfaceControllerApi - functional programming interface
 * @export
 */
export const InterfaceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterfaceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Interfacee} interfacee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInterface(interfacee: Interfacee, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddInterface200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInterface(interfacee, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceControllerApi.addInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} interfaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInterface(interfaceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeepAlive200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInterface(interfaceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceControllerApi.deleteInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterfaces(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInterfaces200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterfaces(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceControllerApi.getInterfaces']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} interfaceId 
         * @param {Interfacee} interfacee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInterface(interfaceId: string, interfacee: Interfacee, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddInterface200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInterface(interfaceId, interfacee, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterfaceControllerApi.updateInterface']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InterfaceControllerApi - factory interface
 * @export
 */
export const InterfaceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterfaceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Interfacee} interfacee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInterface(interfacee: Interfacee, options?: any): AxiosPromise<AddInterface200Response> {
            return localVarFp.addInterface(interfacee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} interfaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterface(interfaceId: string, options?: any): AxiosPromise<KeepAlive200Response> {
            return localVarFp.deleteInterface(interfaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaces(options?: any): AxiosPromise<GetInterfaces200Response> {
            return localVarFp.getInterfaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} interfaceId 
         * @param {Interfacee} interfacee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterface(interfaceId: string, interfacee: Interfacee, options?: any): AxiosPromise<AddInterface200Response> {
            return localVarFp.updateInterface(interfaceId, interfacee, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterfaceControllerApi - interface
 * @export
 * @interface InterfaceControllerApi
 */
export interface InterfaceControllerApiInterface {
    /**
     * 
     * @param {Interfacee} interfacee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApiInterface
     */
    addInterface(interfacee: Interfacee, options?: RawAxiosRequestConfig): AxiosPromise<AddInterface200Response>;

    /**
     * 
     * @param {string} interfaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApiInterface
     */
    deleteInterface(interfaceId: string, options?: RawAxiosRequestConfig): AxiosPromise<KeepAlive200Response>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApiInterface
     */
    getInterfaces(options?: RawAxiosRequestConfig): AxiosPromise<GetInterfaces200Response>;

    /**
     * 
     * @param {string} interfaceId 
     * @param {Interfacee} interfacee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApiInterface
     */
    updateInterface(interfaceId: string, interfacee: Interfacee, options?: RawAxiosRequestConfig): AxiosPromise<AddInterface200Response>;

}

/**
 * InterfaceControllerApi - object-oriented interface
 * @export
 * @class InterfaceControllerApi
 * @extends {BaseAPI}
 */
export class InterfaceControllerApi extends BaseAPI implements InterfaceControllerApiInterface {
    /**
     * 
     * @param {Interfacee} interfacee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApi
     */
    public addInterface(interfacee: Interfacee, options?: RawAxiosRequestConfig) {
        return InterfaceControllerApiFp(this.configuration).addInterface(interfacee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} interfaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApi
     */
    public deleteInterface(interfaceId: string, options?: RawAxiosRequestConfig) {
        return InterfaceControllerApiFp(this.configuration).deleteInterface(interfaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApi
     */
    public getInterfaces(options?: RawAxiosRequestConfig) {
        return InterfaceControllerApiFp(this.configuration).getInterfaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} interfaceId 
     * @param {Interfacee} interfacee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfaceControllerApi
     */
    public updateInterface(interfaceId: string, interfacee: Interfacee, options?: RawAxiosRequestConfig) {
        return InterfaceControllerApiFp(this.configuration).updateInterface(interfaceId, interfacee, options).then((request) => request(this.axios, this.basePath));
    }
}

