/**
 * @author Saad <mohammed.saadullah@314ecorp.com>
 * @description Settings config
 */

import { ROLES } from 'constants/roles';



import SETTINGS from './settings-url.config';
/**
 * NOTE: Make sure routeUrl, url, key fields matches
 */
const SETTINGS_CONFIG = [
	{
		key: 'personal',
		title: 'Personal',
		children: [
			{
				key: 'notifications',
				title: 'Notifications',
				matomoTitle: 'Settings - Notifications',
				subtitle: 'Subscribe to receive email notifications and in-app notifications',
				visible: [ROLES.standaloneLaunch],
				routeUrl: '/settings/notifications',
				url: 'notifications',
				component: 'Notifications',
			},
		],
	},
	{
		key: 'account-setup',
		title: 'Account Setup',
		children: [
			{
				key: 'admin-settings',
				title: 'Admin Settings',
				matomoTitle: 'Settings - Admin Settings',
				subtitle: 'Manage Admin Settings',
				visible: [ROLES.manageOrganisation],
				routeUrl: '/settings/admin-settings',
				url: 'admin-settings',
				component: 'AdminSettings',
			},
			{
				key: 'workspace',
				title: 'Workspace',
				matomoTitle: 'Settings - Workspace',
				subtitle: 'Add basic company information',
				visible: [ROLES.manageOrganisation],
				routeUrl: '/settings/workspace',
				url: 'workspace',
				component: 'Workspace',
			},
			{
				key: 'users',
				title: 'Users',
				matomoTitle: 'Settings - Users',
				subtitle: 'Add and manage user permissions',
				visible: [ROLES.manageUsers],
				routeUrl: '/settings/users',
				url: 'users',
				component: 'ManageAccess',
			},
			{
				key: 'fax-numbers',
				title: 'Fax Numbers',
				matomoTitle: 'Settings - Fax Numbers',
				subtitle: 'Manage & setup fax numbers',
				visible: [ROLES.manageFaxes],
				routeUrl: '/settings/fax-numbers',
				url: 'fax-numbers',
				component: 'FaxNumbers',
			},
		],
	},
	{
		key: 'data-management',
		title: 'Data Management',
		children: [
			{
				key: 'manage-document-types',
				title: 'Document Types',
				subtitle: 'Create/edit and delete document types, attributes and lists',
				visible: [ROLES.manageDocumentType],
				routeUrl: '/settings/manage-document-types',
				url: 'manage-document-types',
				component: 'DocumentTypes',
			},
			{
				key: 'manage-attributes',
				title: 'Attributes',
				subtitle: 'Manage Attributes',
				visible: [ROLES.manageDocumentType],
				routeUrl: '/settings/manage-attributes',
				url: 'manage-attributes',
				component: 'ManageAttributes',
			},
			{
				key: 'manage-lists',
				title: 'List',
				subtitle: 'Manage Lists',
				visible: [ROLES.manageDocumentType],
				routeUrl: '/settings/manage-lists',
				url: 'manage-lists',
				component: 'ManageLists',
			},
			{
				key: 'manage-queues',
				title: 'Queues',
				subtitle: 'Create/modify work queues',
				visible: [ROLES.manageQueues],
				routeUrl: '/settings/manage-queues',
				url: 'manage-queues',
				component: 'QueuesAdmin',
			},
			{
				key: 'templates',
				title: 'Templates',
				matomoTitle: 'Settings - Templates',
				visible: [ROLES.standaloneLaunch],
				routeUrl: '/settings/templates',
				url: 'templates',
				component: 'Templates',
			},
			{
				key: 'stamps',
				title: 'Stamps',
				subtitle: 'Create or edit document stamps',
				visible: [ROLES.standaloneLaunch],
				routeUrl: '/settings/stamps',
				url: 'stamps',
				component: 'StampSetting',
			},
			{
				key: 'interfaces',
				title: 'Interfaces',
				visible: [ROLES.accessZsegmentScreen],
				routeUrl: '/settings/interfaces',
				url: 'interfaces',
				component: 'Interfaces',
			},
			{
				key: 'messages',
				title: 'Messages',
				visible: [ROLES.accessZsegmentScreen],
				routeUrl: '/settings/messages',
				url: 'messages',
				component: 'Messages',
			}
		],
	},
];

const EXTRA_PATHS = [
	// Custom Attributes
	{
		key: 'create-attribute',
		title: 'Create Attribute',
		matomoTitle: 'Settings - Create Attribute',
		url: 'manage-attributes/create',
		routeUrl: SETTINGS.manageAttributes.children.add.url,
		visible: [ROLES.manageDocumentType],
		component: 'CustomAttributeForm',
	},
	{
		key: 'update-attribute',
		title: 'Update Attribute',
		matomoTitle: 'Settings - Update Attribute',
		url: 'manage-attributes/:id',
		routeUrl: SETTINGS.manageAttributes.children.edit.url,
		visible: [ROLES.manageDocumentType],
		component: 'CustomAttributeForm',
	},

	// Document Types
	{
		key: 'create-document-type',
		title: 'Create Document Type',
		matomoTitle: 'Settings - Create Document Type',
		url: 'manage-document-types/create',
		routeUrl: SETTINGS.documentType.children.add.url,
		visible: [ROLES.manageDocumentType],
		component: 'DocumentTypeForm',
	},
	{
		key: 'update-document-type',
		title: 'Update Document Type',
		matomoTitle: 'Settings - Update Document Type',
		url: 'manage-document-types/:id',
		routeUrl: SETTINGS.documentType.children.edit.url,
		visible: [ROLES.manageDocumentType],
		component: 'DocumentTypeForm',
	},

	// Users
	{
		key: 'add-user',
		title: 'Add User',
		matomoTitle: 'Settings - Add User',
		url: 'users/add',
		routeUrl: SETTINGS.users.children.add.url,
		visible: [ROLES.manageUsers],
		component: 'UserForm',
	},
	{
		key: 'update-user-access',
		title: 'Edit User',
		matomoTitle: 'Settings - Edit User',
		url: 'users/:id',
		routeUrl: SETTINGS.users.children.edit.url,
		visible: [ROLES.manageUsers],
		component: 'UserForm',
	},

	// Queues
	{
		key: 'create-queue',
		title: 'Create new queue',
		matomoTitle: 'Settings - Create Queue',
		url: 'manage-queues/create',
		routeUrl: SETTINGS.manageQueues.children.add.url,
		visible: [ROLES.manageQueues],
		component: 'ManageQueues',
	},
	{
		key: 'update-queue',
		title: 'Edit Queue',
		matomoTitle: 'Settings - Edit Queue',
		url: 'manage-queues/:id',
		routeUrl: SETTINGS.manageQueues.children.edit.url,
		visible: [ROLES.manageQueues],
		component: 'ManageQueues',
	},
	// Interfaces
	{
		key: 'interface-details',
		title: 'Interface Details',
		matomoTitle: 'Settings - Interface Details',
		url: 'interfaces/:id',
		routeUrl: SETTINGS.interfaces.children.details.url,
		visible: [ROLES.standaloneLaunch],
		component: 'InterfaceDetails',
	},
];

export { SETTINGS_CONFIG, EXTRA_PATHS };
