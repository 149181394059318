/**
 * @author Vaibhav <vaibhav.mane@314ecorp.com>
 * @description stamp store
 */

import _ from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { StampResponseModel } from '@dexit/common/openapi';
import { defaultStampDims } from 'constants/index';

export interface RectAnnotation {
	id: string;
	top?: number;
	left?: number;
	width?: number;
	height?: number;
	pageIndex?: number;
	field?: string[];
	draftId?: string;
	extractedText?: string;
	newlyAdded?: boolean;
	pageId?: string;
}

type State = {
	stamp: StampResponseModel | null;
	stampPosition: { top: number; left: number };
	stampPageIndex: number;
	stampSize: { width: number; height: number };
	tempSelectedStamp: StampResponseModel | null;

	isRectangleAnnotationMode: boolean;
	rectangles: RectAnnotation[];
	focusedField: string[] | null;
	editingAnnotationId: string | null;
	drawingAnnotationId: string | null;
	hoveredAnnotationId: string | null;
};

type Actions = {
	setStamp: (stamp: StampResponseModel | null) => { stamp: StampResponseModel | null };
	setStampPosition: (position: { top: number; left: number }) => { position: { top: number; left: number } };
	setStampPageIndex: (stampPageIndex: number) => { stampPageIndex: number };
	setStampSize: (size: { width: number; height: number }) => { size: { width: number; height: number } };
	setTempSelectedStamp: (tempSelectedStamp: StampResponseModel | null) => {
		tempSelectedStamp: StampResponseModel | null;
	};

	setRectangleAnnotationMode: (isRectangleAnnotationMode: boolean) => { isRectangleAnnotationMode: boolean };
	addRectangle: (rectangle: RectAnnotation) => { rectangle: RectAnnotation };
	removeRectangle: (id: string) => { id: string };
	updateRectangle: (rectangle: RectAnnotation) => { rectangle: RectAnnotation };
	setRectangles: (rectangles: RectAnnotation[]) => { rectangles: RectAnnotation[] };
	setFocusedField: (focusedField: string[] | null) => { focusedField: string[] | null };
	setEditingAnnotationId: (editingAnnotationId: string | null) => { editingAnnotationId: string | null };
	setDrawingAnnotationId: (drawingAnnotationId: string | null) => { drawingAnnotationId: string | null };
	setHoveredAnnotationId: (hoveredAnnotationId: string | null) => { hoveredAnnotationId: string | null };
	reset: () => void;
	exitRectangleAnnotationMode: () => void;
};

const initialState: State = {
	stamp: null,
	stampPosition: { top: defaultStampDims.top, left: defaultStampDims.left },
	stampPageIndex: 0,
	stampSize: { width: defaultStampDims.width, height: defaultStampDims.height },
	tempSelectedStamp: null,

	isRectangleAnnotationMode: false,
	rectangles: [],
	focusedField: null,
	editingAnnotationId: null,
	drawingAnnotationId: null,
	hoveredAnnotationId: null,
};

const actions = (set: any): Actions => ({
	setStamp: (stamp) => set({ stamp }),
	setStampPosition: (stampPosition) => set({ stampPosition }),
	setStampPageIndex: (stampPageIndex) => set({ stampPageIndex }),
	setStampSize: (stampSize) => set({ stampSize }),
	setTempSelectedStamp: (tempSelectedStamp) => set({ tempSelectedStamp }),

	setRectangleAnnotationMode: (isRectangleAnnotationMode) => set({ isRectangleAnnotationMode }),
	addRectangle: (rectangle) => set((state: State) => ({ rectangles: [...state.rectangles, rectangle] })),
	removeRectangle: (id: string) =>
		set((state: State) => ({
			rectangles: _.filter(state.rectangles, (rectangle) => rectangle.id !== id),
		})),
	updateRectangle: (rectangle) =>
		set((state: State) => ({
			rectangles: _.map(state.rectangles, (rect) =>
				rect.id === rectangle.id ? { ...rect, ...rectangle } : rect,
			),
		})),
	setRectangles: (newRectangles) => set((state: State) => ({ rectangles: [...state.rectangles, ...newRectangles] })),
	setFocusedField: (focusedField) => set({ focusedField }),
	setEditingAnnotationId: (editingAnnotationId) => set({ editingAnnotationId }),
	setDrawingAnnotationId: (drawingAnnotationId) => set({ drawingAnnotationId }),
	setHoveredAnnotationId: (hoveredAnnotationId) => set({ hoveredAnnotationId }),
	reset: () => set(initialState),

	exitRectangleAnnotationMode: () =>
		set({
			isRectangleAnnotationMode: false,
			focusedField: null,
			editingAnnotationId: null,
			drawingAnnotationId: null,
			hoveredAnnotationId: null,
		}),
});

const useAnnotationValues = (): State =>
	useAnnotationStore((state) => ({
		stamp: state.stamp,
		stampPosition: state.stampPosition,
		stampPageIndex: state.stampPageIndex,
		stampSize: state.stampSize,
		tempSelectedStamp: state.tempSelectedStamp,

		isRectangleAnnotationMode: state.isRectangleAnnotationMode,
		rectangles: state.rectangles,
		focusedField: state.focusedField,
		editingAnnotationId: state.editingAnnotationId,
		drawingAnnotationId: state.drawingAnnotationId,
		hoveredAnnotationId: state.hoveredAnnotationId,
	}));

const useAnnotationActions = (): Actions =>
	useAnnotationStore((state) => ({
		setStamp: state.setStamp,
		setStampPosition: state.setStampPosition,
		setStampPageIndex: state.setStampPageIndex,
		setStampSize: state.setStampSize,
		setTempSelectedStamp: state.setTempSelectedStamp,

		setRectangleAnnotationMode: state.setRectangleAnnotationMode,
		addRectangle: state.addRectangle,
		removeRectangle: state.removeRectangle,
		updateRectangle: state.updateRectangle,
		setRectangles: state.setRectangles,
		setFocusedField: state.setFocusedField,
		setEditingAnnotationId: state.setEditingAnnotationId,
		setDrawingAnnotationId: state.setDrawingAnnotationId,
		setHoveredAnnotationId: state.setHoveredAnnotationId,
		exitRectangleAnnotationMode: state.exitRectangleAnnotationMode,
		reset: state.reset,
	}));

const useAnnotationStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			...actions(set),
		}),
		{ name: 'Stamp Store' },
	),
);

export default useAnnotationStore;
export { useAnnotationValues, useAnnotationActions };
