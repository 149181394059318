/* tslint:disable */
/* eslint-disable */
/**
 * Zsegment
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../Models';
// @ts-ignore
import { FetchInstantMetrics200Response } from '../Models';
// @ts-ignore
import { GetInterfaceMetrics200Response } from '../Models';
// @ts-ignore
import { GetRangedInterfaceMetrics200Response } from '../Models';
// @ts-ignore
import { RangedMetricsRequest } from '../Models';
// @ts-ignore
import { SystemMetricsRequest } from '../Models';
/**
 * MetricsControllerApi - axios parameter creator
 * @export
 */
export const MetricsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SystemMetricsRequest} systemMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInstantMetrics: async (systemMetricsRequest: SystemMetricsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemMetricsRequest' is not null or undefined
            assertParamExists('fetchInstantMetrics', 'systemMetricsRequest', systemMetricsRequest)
            const localVarPath = `/metrics/instant/system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemMetricsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SystemMetricsRequest} systemMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMetrics: async (systemMetricsRequest: SystemMetricsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemMetricsRequest' is not null or undefined
            assertParamExists('fetchMetrics', 'systemMetricsRequest', systemMetricsRequest)
            const localVarPath = `/metrics/range/system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemMetricsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} interfaceId 
         * @param {string} range 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaceMetrics: async (interfaceId: Array<string>, range: string, step: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'interfaceId' is not null or undefined
            assertParamExists('getInterfaceMetrics', 'interfaceId', interfaceId)
            // verify required parameter 'range' is not null or undefined
            assertParamExists('getInterfaceMetrics', 'range', range)
            // verify required parameter 'step' is not null or undefined
            assertParamExists('getInterfaceMetrics', 'step', step)
            const localVarPath = `/metrics/instant/interface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (interfaceId) {
                localVarQueryParameter['interface_id'] = interfaceId.join(COLLECTION_FORMATS.csv);
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RangedMetricsRequest} rangedMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangedInterfaceMetrics: async (rangedMetricsRequest: RangedMetricsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rangedMetricsRequest' is not null or undefined
            assertParamExists('getRangedInterfaceMetrics', 'rangedMetricsRequest', rangedMetricsRequest)
            const localVarPath = `/metrics/range/interface`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rangedMetricsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RangedMetricsRequest} rangedMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangedRouteMetrics: async (rangedMetricsRequest: RangedMetricsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rangedMetricsRequest' is not null or undefined
            assertParamExists('getRangedRouteMetrics', 'rangedMetricsRequest', rangedMetricsRequest)
            const localVarPath = `/metrics/range/route`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rangedMetricsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {string} range 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRouteMetrics: async (routeId: Array<string>, range: string, step: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeId' is not null or undefined
            assertParamExists('getRouteMetrics', 'routeId', routeId)
            // verify required parameter 'range' is not null or undefined
            assertParamExists('getRouteMetrics', 'range', range)
            // verify required parameter 'step' is not null or undefined
            assertParamExists('getRouteMetrics', 'step', step)
            const localVarPath = `/metrics/instant/route`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2Auth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2Auth", ["email", "profile"], configuration)

            if (routeId) {
                localVarQueryParameter['route_id'] = routeId.join(COLLECTION_FORMATS.csv);
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsControllerApi - functional programming interface
 * @export
 */
export const MetricsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SystemMetricsRequest} systemMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchInstantMetrics(systemMetricsRequest: SystemMetricsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchInstantMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInstantMetrics(systemMetricsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsControllerApi.fetchInstantMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SystemMetricsRequest} systemMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMetrics(systemMetricsRequest: SystemMetricsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchInstantMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMetrics(systemMetricsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsControllerApi.fetchMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} interfaceId 
         * @param {string} range 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterfaceMetrics(interfaceId: Array<string>, range: string, step: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInterfaceMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterfaceMetrics(interfaceId, range, step, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsControllerApi.getInterfaceMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RangedMetricsRequest} rangedMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRangedInterfaceMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRangedInterfaceMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRangedInterfaceMetrics(rangedMetricsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsControllerApi.getRangedInterfaceMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RangedMetricsRequest} rangedMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRangedRouteMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRangedInterfaceMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRangedRouteMetrics(rangedMetricsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsControllerApi.getRangedRouteMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {string} range 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRouteMetrics(routeId: Array<string>, range: string, step: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInterfaceMetrics200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRouteMetrics(routeId, range, step, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsControllerApi.getRouteMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetricsControllerApi - factory interface
 * @export
 */
export const MetricsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SystemMetricsRequest} systemMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInstantMetrics(systemMetricsRequest: SystemMetricsRequest, options?: any): AxiosPromise<FetchInstantMetrics200Response> {
            return localVarFp.fetchInstantMetrics(systemMetricsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SystemMetricsRequest} systemMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMetrics(systemMetricsRequest: SystemMetricsRequest, options?: any): AxiosPromise<FetchInstantMetrics200Response> {
            return localVarFp.fetchMetrics(systemMetricsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} interfaceId 
         * @param {string} range 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterfaceMetrics(interfaceId: Array<string>, range: string, step: string, options?: any): AxiosPromise<GetInterfaceMetrics200Response> {
            return localVarFp.getInterfaceMetrics(interfaceId, range, step, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RangedMetricsRequest} rangedMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangedInterfaceMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: any): AxiosPromise<GetRangedInterfaceMetrics200Response> {
            return localVarFp.getRangedInterfaceMetrics(rangedMetricsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RangedMetricsRequest} rangedMetricsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRangedRouteMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: any): AxiosPromise<GetRangedInterfaceMetrics200Response> {
            return localVarFp.getRangedRouteMetrics(rangedMetricsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} routeId 
         * @param {string} range 
         * @param {string} step 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRouteMetrics(routeId: Array<string>, range: string, step: string, options?: any): AxiosPromise<GetInterfaceMetrics200Response> {
            return localVarFp.getRouteMetrics(routeId, range, step, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsControllerApi - interface
 * @export
 * @interface MetricsControllerApi
 */
export interface MetricsControllerApiInterface {
    /**
     * 
     * @param {SystemMetricsRequest} systemMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApiInterface
     */
    fetchInstantMetrics(systemMetricsRequest: SystemMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<FetchInstantMetrics200Response>;

    /**
     * 
     * @param {SystemMetricsRequest} systemMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApiInterface
     */
    fetchMetrics(systemMetricsRequest: SystemMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<FetchInstantMetrics200Response>;

    /**
     * 
     * @param {Array<string>} interfaceId 
     * @param {string} range 
     * @param {string} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApiInterface
     */
    getInterfaceMetrics(interfaceId: Array<string>, range: string, step: string, options?: RawAxiosRequestConfig): AxiosPromise<GetInterfaceMetrics200Response>;

    /**
     * 
     * @param {RangedMetricsRequest} rangedMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApiInterface
     */
    getRangedInterfaceMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetRangedInterfaceMetrics200Response>;

    /**
     * 
     * @param {RangedMetricsRequest} rangedMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApiInterface
     */
    getRangedRouteMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetRangedInterfaceMetrics200Response>;

    /**
     * 
     * @param {Array<string>} routeId 
     * @param {string} range 
     * @param {string} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApiInterface
     */
    getRouteMetrics(routeId: Array<string>, range: string, step: string, options?: RawAxiosRequestConfig): AxiosPromise<GetInterfaceMetrics200Response>;

}

/**
 * MetricsControllerApi - object-oriented interface
 * @export
 * @class MetricsControllerApi
 * @extends {BaseAPI}
 */
export class MetricsControllerApi extends BaseAPI implements MetricsControllerApiInterface {
    /**
     * 
     * @param {SystemMetricsRequest} systemMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    public fetchInstantMetrics(systemMetricsRequest: SystemMetricsRequest, options?: RawAxiosRequestConfig) {
        return MetricsControllerApiFp(this.configuration).fetchInstantMetrics(systemMetricsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SystemMetricsRequest} systemMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    public fetchMetrics(systemMetricsRequest: SystemMetricsRequest, options?: RawAxiosRequestConfig) {
        return MetricsControllerApiFp(this.configuration).fetchMetrics(systemMetricsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} interfaceId 
     * @param {string} range 
     * @param {string} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    public getInterfaceMetrics(interfaceId: Array<string>, range: string, step: string, options?: RawAxiosRequestConfig) {
        return MetricsControllerApiFp(this.configuration).getInterfaceMetrics(interfaceId, range, step, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RangedMetricsRequest} rangedMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    public getRangedInterfaceMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: RawAxiosRequestConfig) {
        return MetricsControllerApiFp(this.configuration).getRangedInterfaceMetrics(rangedMetricsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RangedMetricsRequest} rangedMetricsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    public getRangedRouteMetrics(rangedMetricsRequest: RangedMetricsRequest, options?: RawAxiosRequestConfig) {
        return MetricsControllerApiFp(this.configuration).getRangedRouteMetrics(rangedMetricsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} routeId 
     * @param {string} range 
     * @param {string} step 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsControllerApi
     */
    public getRouteMetrics(routeId: Array<string>, range: string, step: string, options?: RawAxiosRequestConfig) {
        return MetricsControllerApiFp(this.configuration).getRouteMetrics(routeId, range, step, options).then((request) => request(this.axios, this.basePath));
    }
}

