/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Config Constants
 */

export const DEFAULT_SERVER_URL = 'https://test5.api.dexit.tech';

// Keycloak
export const AUTH_URL_INTEGRATION = 'https://auth.314ecorp.tech/auth';
export const AUTH_URL_PRODUCTION = 'https://auth.314ecorp.com/auth';

// Matomo
export const MATOMO_URL_INTEGRATION = 'https://analytics.314ecorp.tech/';
export const MATOMO_URL_PRODUCTION = 'https://analytics.314ecorp.com';

// Highlight
export const HIGHLIGHT_PROJECT_ID = 'neyqv1g4';

// Marker
export const MARKER_PROJECT_ID = '64a5197d0341774a5d089ef1';

// Zsegment
export const ZSEGMENT_BASE_URL = 'https://test6.api.zsegment.tech/api/v1';
