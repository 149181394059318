/**
 * @author Karthik <karthik.x@314ecorp.com>
 * @description Agent config
 */

import Environment from '@dexit/common/utils/Environment';
import { DEFAULT_SERVER_URL, ZSEGMENT_BASE_URL } from '@dexit/common/constants/url';
import { realm } from 'configs/config.integration';
import { environmentHelperInstance } from '@dexit/admin/src/utils/EnvironmentHelper';

const env = environmentHelperInstance.env;

export default {
	dexitclient: {
		baseURL: Environment.isLocalhost() ? DEFAULT_SERVER_URL : `https://${realm}.api.dexit.${env}`,
		headers: { 'Content-Type': 'application/json' },
		withCredentials: true,
	},
	zsegmentClient: {
		baseURL: ZSEGMENT_BASE_URL,
		headers: { 'Content-Type': 'application/json' },
		withCredentials: false,
	},
};
